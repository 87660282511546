<template>
  <div class="results pb-5">
    <total />
  </div>
</template>

<script>
import total from "@/components/Total.vue";
import TC_Wrapper from "vue-tag-commander";

export default {
  name: "Results",
  components: {
    total
  },
  mounted() {
    // Récupérer l'instance de TC_Wrapper
    const wrapper = TC_Wrapper.getInstance();

    // Mettre à jour les variables souhaitées
    wrapper.setTcVars({
      environnement_technique_du_site: window.location.href.includes("www.parlons-fourrage.credit-agricole.fr") ? "prod" : "preprod",
      environnement_nom_du_site: "Parlons fourrage",
      page: "Resultat",
      environnement_distributeur: "CA",
      environnement_id_CR: "98100",
      page_url: window.location.href,
      siteID: "parlons-fourrage"
    });
    document.getElementById('pageVirtuelle').click();
    //console.log('page result');
  }
};
</script>

<style scoped>
.results {
  color: #006a4e;
  max-width: 700px;
  margin: 0 auto;
  flex-grow: 1;
}
</style>
