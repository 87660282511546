<template>
  <!--Question numéro : {{index + 1}} Bonnes réponses : {{countRight}}-->
  <div class="row overflow-hidden flex-nowrap">
    <transition name="slide-categorie">
      <div class="col-12 d-flex" :key="progression">
        <div class="row">
          <!-- Les bonnes illustrations s'affichent suivant le bon personnage et la catégorie actuelle -->
          <div
            v-if="character == 'Joris'"
            class="col-sm d-flex align-items-center mb-5 mb-sm-0"
          >
            <img
              class="img-fluid"
              v-if="questionList.questions[index].categorie === 'Préambule'"
              src="@/assets/img/visu-fauche@2x.jpg"
            />
            <img
              class="img-fluid"
              v-else-if="questionList.questions[index].categorie === 'Fauche'"
              src="@/assets/img/visu-fanage@2x.jpg"
            />
            <img
              class="img-fluid"
              v-else-if="questionList.questions[index].categorie === 'Pressage'"
              src="@/assets/img/visu-pressage@2x.jpg"
            />
            <img
              class="img-fluid"
              v-else-if="questionList.questions[index].categorie === 'Stockage'"
              src="@/assets/img/visu-stockage@2x.jpg"
            />
            <img
              class="img-fluid"
              v-else-if="
                questionList.questions[index].categorie === 'Et vous ?'
              "
              src="@/assets/img/visu-et-vous@2x.jpg"
            />
          </div>
          <div
            v-if="character == 'Flora'"
            class="col-sm d-flex align-items-center mb-5 mb-sm-0"
          >
            <img
              class="img-fluid"
              v-if="questionList.questions[index].categorie === 'Préambule'"
              src="@/assets/img/visu-fauche-flora@2x.jpg"
            />
            <img
              class="img-fluid"
              v-else-if="questionList.questions[index].categorie === 'Fauche'"
              src="@/assets/img/visu-fanage-flora@2x.jpg"
            />
            <img
              class="img-fluid"
              v-else-if="questionList.questions[index].categorie === 'Pressage'"
              src="@/assets/img/visu-pressage@2x.jpg"
            />
            <img
              class="img-fluid"
              v-else-if="questionList.questions[index].categorie === 'Stockage'"
              src="@/assets/img/visu-stockage-flora@2x.jpg"
            />
            <img
              class="img-fluid"
              v-else-if="
                questionList.questions[index].categorie === 'Et vous ?'
              "
              src="@/assets/img/visu-et-vous@2x.jpg"
            />
          </div>
          <div
            class="col-sm d-flex align-items-center justify-content-center animation-container"
          >
            <transition name="fade">
              <div v-if="!pushInfo" key="1">
                <question
                  :questionList="questionList.questions"
                  :index="index"
                />
                <!-- On écoute l'événement buttonClicked. Lorsqu'il est émis, on lance la méthode indexChange() -->
                <answers
                  :questionList="questionList.questions"
                  :index="index"
                  @answerPicked="answerCheck($event)"
                />
              </div>
              <div v-else key="2">
                <!--S'il y a un push info, cette div s'affiche et montre le pushInfo ainsi qu'un bouton pour passer à la question suivante-->
                <explanation
                  :answerInfo="answerInfo"
                  :redirect="redirect"
                  @nextQuestion="newQuestion($event)"
                />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div class="row w-100">
    <div class="col-sm">
      <div class="progress-container mt-5 mb-5">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: ' + progression + '%'"
            aria-valuenow="10"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div class="progress-elements">
          <span class="current">Préambule</span>
          <span :class="[progression >= 30 ? 'current' : '']">Fauche</span>
          <span :class="[progression >= 50 ? 'current' : '']">Pressage</span>
          <span :class="[progression >= 70 ? 'current' : '']">Stockage</span>
          <span :class="[progression >= 90 ? 'current' : '']">Et vous ?</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import question from "@/components/Question.vue";
import answers from "@/components/Answers.vue";
import explanation from "@/components/Explanation.vue";
import TC_Wrapper from "vue-tag-commander";

export default {
  name: "Quiz",
  components: {
    /*Chaque import doit être enregistré dans les components*/
    question,
    answers,
    explanation
  },
  created() {
    if (this.character == "") window.location = "/";

    window.addEventListener("beforeunload", function(e) {
      var confirmationMessage = "";
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    });
  },
  mounted() {
    this.$emit("quiz-loaded");

    const wrapper = TC_Wrapper.getInstance();

    // Mettre à jour les variables souhaitées
    wrapper.setTcVars({
      environnement_technique_du_site: window.location.href.includes("www.parlons-fourrage.credit-agricole.fr") ? "prod" : "preprod",
      environnement_nom_du_site: "Parlons fourrage",
      page: "Quiz",
      environnement_distributeur: "CA",
      environnement_id_CR: "98100",
      page_url: window.location.href,
      siteID: "parlons-fourrage"
    });
    document.getElementById('pageVirtuelle').click();
    //console.log('page quiz');
  },
  data() {
    return {
      /* Charge le quiz enregistré dans le store*/
      questionList: this.$store.state.updatedQuiz,
      /*Index est le numéro de la question */
      index: 0,
      pushInfo: false,
      redirect: false,
      /* Answerinfo est vide par défaut, et reçoit le push Info si besoin*/
      answerInfo: "",
      progression: 10,
      categorie: "Préambule",
      /* Charge le personnage enregistré dans le store*/
      character: this.$store.state.characterChoice,
      specialScoreTotal: 0
    };
  },
  methods: {
    answerCheck(answer) {
      let questionList = this.questionList.questions;

      // Si la réponse est bonne, on incrémente le compteur de bonnes réponses
      if (answer.right === true) {
        //On enregistre l'incrément dans le store
        //console.log('rightAnswersBefore',this.$store.state.rightAnswers);
        this.$store.commit(
          "countRightAnswers",
          (this.$store.state.rightAnswers += 1)
        );
        //console.log('rightAnswersAfter',this.$store.state.rightAnswers);
      }

      //Pour la question fanage dont les bonnes réponses dépendent de la question précédente, on a prévu un flag spécial
      if (questionList[this.index].fanageFlag == true) {
        this.dryingFanageQuestion(questionList, answer);
      }

      //Pour les questions de la catégorie "Et vous?", on incrémente un score spécialement dédié à la catégorie.
      if (questionList[this.index].specialScore == true) {
        this.specialScore(answer);
        if (questionList[this.index].finalQuestionFlag === true) {
          this.specialCategory(answer);
        }
      }

      // On ajoute au json la valeur result ainsi que le potentiel conseil de la réponse s'il a choisit faux
      questionList[this.index].result = answer.right;
      questionList[this.index].answerExplained = answer.advice;
      //On met à jour le json dans le store également
      this.$store.commit("updatingQuiz", questionList);

      //S'il n'y a pas de pushInfo, on passe directement à la question suivante
      if (answer.explanation == undefined) {
        this.index++;
        this.updateCategorie();
      } else {
        // Texte du pushInfo après avoir cliqué sur une question
        this.answerInfo = answer.explanation;

        // On affiche le push info après avoir répondu à la question
        this.pushInfo = true;
      }
      // Si on est à la dernière question, on redirige à la dernière page
      if (this.index === questionList.length - 1) {
        this.redirect = true;
      }
    },
    newQuestion() {
      //Lorsqu'il y a un push Info, une fois le bouton "question suivante" cliquée, on fait disparaître le push info et on passe à la question suivante
      this.pushInfo = false;
      this.index++;
      this.updateCategorie();
    },
    updateCategorie() {
      //Permet de mettre à jour la variable catégorie afin que le tracker puisse suivre
      if (
        this.categorie !== this.questionList.questions[this.index].categorie
      ) {
        this.progression = this.progression + 20;
      }
      this.categorie = this.questionList.questions[this.index].categorie;
    },
    specialScore(answer) {
      this.specialScoreTotal += answer.points;
      //Permet de garder trace des points pour les questions de la catégorie "Et vous? " qui ne sont pas comptés dans le score final
    },
    specialCategory(answer) {
      //Permet d'afficher un pushInfo personnalisé suivant le score du mini-quiz dans "Et vous ?"
      if (this.specialScoreTotal >= 7) {
        answer.explanation =
          "Bravo ! La vigilance est constante! Vous assurez la qualité de votre foin et les échauffements des fourrages sont minimisés ! Continuez !";
      } else if (this.specialScoreTotal >= 4) {
        answer.explanation =
          "Vous réalisez de temps en temps voire fréquemment des points de contrôle. Continuez ! Une vigilance accrue permet de limiter les incendies d’échauffement tout en assurant la qualité de votre foin.";
      } else {
        answer.explanation =
          "Attention ! Une vigilance accrue tout au long de la fenaison permet une production de foin de meilleure qualité et une diminution des risques d’incendies !";
      }
    },
    //Cette fonction permet d'afficher un conseil personnalisé suivant le personnage en fonction de la réponse précédente. Elle se situe en dehors du Json car on ne modifie que les section advice et right.
    dryingFanageQuestion(question, answer) {
      if (this.character === "Joris") {
        if (answer.text === "0 fanage") {
          question[this.index + 1].answers[1].right = false;
          question[this.index + 1].answers[2].right = false;
          question[this.index + 1].answers[0].advice =
            "2-3 jours de séchage ne seront sûrement pas suffisants pour que Joris sèche son foin surtout s'il n'y a pas eu de fanage. Joris n'aura sûrement pas un foin sec avant le pressage.";
          question[this.index + 1].answers[1].advice =
            "4-5 jours de séchage auraient pu être suffisants pour que Joris sèche son foin mais il n'a pas effectué de fanages et les conditions météorologiques ne sont pas des plus adéquates. En laissant sécher son foin plus longtemps, Joris diminue les risques d'échauffements.";
          question[this.index + 1].answers[2].advice =
            "6 jours de séchage pourraient être suffisants… Mais pour être sûr d'avoir un foin sec au pressage, Joris aurait dû le faner.";
        } else if (answer.text === "Entre 1 et 3 fanages") {
          question[this.index + 1].answers[0].advice =
            "Même avec des fanages, 2-3 jours de séchage ne seront sûrement pas suffisants pour faire sécher son foin, surtout si l'hygrométrie de l'air est élevée. Joris prend le risque de presser un foin humide ! Son foin va sûrement s'échauffer…";
        } else if (answer.text === "Plus de 4 fanages") {
          question[this.index + 1].answers[0].advice =
            "2-3 jours de séchage pourraient être suffisant avec un grand nombre de fanages. Mais Joris doit rester vigilant, car les conditions météorologiques ne sont pas des plus adéquates pour le séchage. S'il veut éviter au maximum les échauffements, il doit laisser sécher son foin plus longtemps.";
        }
      } else if (this.character === "Flora") {
        if (answer.text === "0 fanage") {
          question[this.index + 1].answers[1].right = false;
          question[this.index + 1].answers[2].right = false;
          question[this.index + 1].answers[0].advice =
            "2-3 jours de séchage ne seront sûrement pas suffisants pour que Flora sèche son foin surtout s'il n'y a pas eu de fanage. Flora n'aura sûrement pas un foin sec avant le pressage.";
          question[this.index + 1].answers[1].advice =
            "4-5 jours de séchage auraient pu être suffisants pour que Flora sèche son foin mais elle n'a pas effectué de fanages et les conditions météorologiques ne sont pas des plus adéquates. En laissant sécher son foin plus longtemps, Flora diminue les risques d'échauffements.";
          question[this.index + 1].answers[2].advice =
            "6 jours de séchage pourraient être suffisants… Mais pour être sûr d'avoir un foin sec au pressage, Flora aurait dû le faner.";
        } else if (answer.text === "Entre 1 et 3 fanages") {
          question[this.index + 1].answers[0].advice =
            "Même avec des fanages, 2-3 jours de séchage ne seront sûrement pas suffisants pour faire sécher son foin, surtout si l'hygrométrie de l'air est élevée. Flora prend le risque de presser un foin humide ! Son foin va sûrement s'échauffer…";
        } else if (answer.text === "Plus de 4 fanages") {
          question[this.index + 1].answers[0].advice =
            "2-3 jours de séchage pourraient être suffisant avec un grand nombre de fanages. Mais Flora doit rester vigilant, car les conditions météorologiques ne sont pas des plus adéquates pour le séchage. Si elle veut éviter au maximum les échauffements, elle doit laisser sécher son foin plus longtemps.";
        }
      }
    }
  }
};
</script>
<style scoped>
#app .col-12 > .row,
#app > .row {
  flex-grow: 1;
}

.animation-container {
  min-height: 470px;
}

.animation-container > div {
  transform: translateY(-50%) translateZ(0);
  position: absolute;
  padding-right: 15px;
  padding-left: 15px;
  top: 50%;
}

.fade-enter-active {
  transition: all 1.5s ease;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-categorie-leave-active,
.slide-categorie-enter-active {
  transition: all 1s ease;
}

.slide-categorie-leave-from,
.slide-categorie-enter-from {
  transform: translateX(0);
}

.slide-categorie-enter-to,
.slide-categorie-leave-to {
  transform: translateX(-100%);
}

.progress-container {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.progress {
  background: #99c21c;
  border-radius: 0.5rem;
}

.progress-bar {
  background: #006a4e;
}

.progress-elements {
  display: flex;
  justify-content: center;
  line-height: 1;
}

.progress-elements span {
  flex: 1;
  position: relative;
  padding: 0.5rem 0;
  color: #4dbcad;
}

.progress-elements span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -11px);
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
}

.progress-elements .current {
  color: #006a4e;
}

.progress-elements .current::before {
  background: #006a4e;
  border: 1px solid white;
}
</style>
